import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
 


 <path d="M1300 2540 c-204 -30 -445 -160 -596 -322 -74 -79 -163 -211 -204
-301 -82 -181 -110 -426 -70 -617 43 -211 129 -380 266 -528 130 -141 284
-236 483 -298 l114 -36 211 5 211 4 103 37 c326 115 555 349 673 686 32 94 33
98 37 304 4 227 1 246 -65 416 -117 301 -408 551 -739 636 -81 20 -118 24
-233 23 -75 -1 -161 -5 -191 -9z m330 -33 c75 -11 216 -53 239 -71 8 -6 16
-10 18 -8 2 1 26 -10 54 -25 29 -16 63 -34 78 -42 89 -48 224 -174 301 -282
79 -110 172 -335 169 -408 0 -14 0 -105 0 -201 1 -96 -4 -193 -10 -215 -6 -22
-13 -50 -16 -63 -3 -12 -9 -25 -14 -28 -5 -3 -9 -12 -9 -20 0 -9 -20 -57 -44
-107 -71 -148 -191 -292 -303 -364 -29 -18 -53 -37 -53 -41 0 -4 -30 -23 -67
-41 -38 -18 -93 -46 -124 -62 -66 -34 -71 -35 -62 -12 7 17 6 17 -20 0 -14 -9
-35 -17 -45 -17 -10 0 -23 -5 -29 -11 -14 -14 -350 -14 -422 0 -103 21 -227
69 -316 124 -82 50 -120 81 -193 151 -77 74 -192 227 -192 255 0 6 -4 11 -8
11 -13 0 -68 155 -92 257 -10 44 -9 57 4 84 9 18 14 34 11 37 -2 3 -7 -3 -11
-12 -3 -9 -12 -16 -20 -16 -29 0 -1 308 37 415 32 88 91 215 101 215 4 0 8 4
8 10 0 10 64 104 90 130 8 9 31 34 50 56 41 46 151 134 167 134 6 0 13 4 15 8
5 15 167 104 177 98 5 -4 12 -2 15 3 8 12 97 38 190 56 64 12 250 13 326 2z"/>
<path d="M1569 2016 c-2 -2 -152 -6 -334 -9 -319 -6 -375 -10 -375 -27 0 -5
-8 -11 -17 -14 -10 -4 -36 -23 -58 -42 -22 -20 -33 -33 -25 -29 8 5 4 -3 -8
-17 -17 -19 -26 -45 -32 -91 -4 -35 -13 -74 -19 -85 -10 -18 -9 -21 4 -16 10
4 15 0 15 -10 0 -35 60 -105 113 -132 35 -19 34 -28 -5 -67 -68 -66 -118 -142
-118 -175 1 -9 9 -27 20 -40 19 -23 21 -23 242 -22 123 0 257 3 300 6 42 3 78
1 81 -3 3 -5 8 -62 11 -128 5 -112 8 -121 31 -142 22 -19 36 -22 116 -22 87 0
93 1 120 29 29 28 29 30 29 143 0 62 3 117 6 120 8 8 43 -19 199 -158 58 -51
70 -61 107 -89 20 -15 49 -31 65 -35 22 -6 27 -12 25 -32 l-4 -24 22 23 c12
13 41 32 64 42 54 23 81 48 91 85 8 32 7 550 -1 588 -3 12 -7 29 -9 37 -2 8
-4 18 -4 21 -2 17 -56 26 -136 24 -83 -3 -91 -5 -113 -30 -13 -15 -22 -37 -21
-49 3 -40 -1 -53 -26 -90 -13 -20 -21 -36 -16 -36 5 0 12 8 15 17 11 29 26 -5
26 -59 0 -42 -3 -48 -20 -48 -23 0 -43 40 -33 67 6 16 4 16 -15 -1 -22 -19
-23 -19 -40 0 -10 10 -33 30 -50 44 -18 14 -32 31 -32 38 0 6 -6 12 -14 12 -7
0 -33 19 -57 43 -48 46 -60 55 -131 90 l-47 24 34 33 c19 19 35 38 35 42 0 5
9 19 21 31 21 23 49 80 49 102 0 28 -64 76 -81 61z m57 -47 c7 -11 -43 -88
-102 -157 l-36 -42 -215 0 c-192 0 -214 -2 -209 -16 3 -9 6 -22 6 -30 0 -13
32 -14 213 -13 160 1 221 -1 249 -12 35 -13 272 -207 401 -328 31 -30 59 -52
61 -50 2 2 -1 82 -6 178 -5 96 -5 177 -1 181 4 4 50 10 100 13 68 3 95 1 103
-8 7 -9 12 -123 13 -324 l2 -310 -27 -27 c-39 -39 -117 -46 -161 -13 -42 31
-310 260 -366 313 l-43 41 6 -45 c7 -52 11 -318 5 -324 -8 -8 -194 -10 -206
-3 -10 6 -13 48 -13 154 l0 145 -147 -6 c-82 -3 -229 -7 -328 -8 -163 -3 -180
-1 -183 14 -2 10 25 54 63 103 l67 85 91 1 c51 1 175 4 277 8 l185 6 0 25 0
25 -235 1 c-250 0 -292 4 -352 35 -107 55 -121 238 -25 321 73 62 78 63 292
68 378 11 513 11 521 -1z"/>
<path d="M1966 795 c-9 -13 -16 -28 -16 -32 0 -3 -8 -16 -17 -28 -10 -12 -1
-3 19 19 21 23 38 47 38 54 0 18 -6 15 -24 -13z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
